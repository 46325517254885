import {
  ElLink,
  ElMessage
} from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default (app) => {
  app.use(ElLink, { locale: zhCn })
  app.use(ElMessage, { locale: zhCn })
}
