<template>
  <div id="home">
    <div class="title">Welcome to <span>www.topquant.tech!</span></div>
    <el-link class="link-button"
             href="https://beian.miit.gov.cn/"
             type="primary">浙ICP备19020044号</el-link>
  </div>
</template>

<script>
export default {
  name: 'Home',
  created () {
    this.hello()
  },
  methods: {
    hello () {
      const dateStart = new Date('2020-06-27') - (8 * 60 * 60 * 1000)
      const dateEnd = Date.now()
      const difValue = Math.floor((dateEnd - dateStart) / (1000 * 60 * 60 * 24))
      const msg = '今天是是第' + difValue + '天,第' + (Math.floor(difValue / 7)) + '周第' + (difValue % 7 + 1) + '天'
      this.$message.success({ message: msg, type: 'success' })
    }
  }
}
</script>

<style lang="less" scoped>
#home{
  height: 100%;
  background-color: #9ac3e2;
  background-image: linear-gradient(330deg, #9ac3e2 0%, #99dddd 100%);
  display: flex;
  align-items: center;
  align-content: center;
}
.title{
  font-size: 80px;
  font-weight: bold;
  text-shadow: 0 0 11px #013457;
  margin: 0 auto;
  padding: 0;
  color: #ffc98b;
  span{
    color: #Fadce4;
  }
}
.title:hover{
  text-shadow: 0 0 13px #013457;
}
.link-button {
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 0;
}

</style>
